import React, { Component } from "react";
import DeckSquare from "./DeckSquare";
import { connect } from "react-redux";
import StorylingButton from "../../../components/buttons/StorylingButton";
import CustomModal from "../../../components/modals/CustomModal";
import { isMobile } from "react-device-detect";
import { fetchDecks, removeDeck, modifyDeck } from "../../../helpers/decks";
import { message } from "antd";
const defaultColor = "#220597";

class DecksSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "decks",
      renameDeckModal: {deck: {}}
    };
  }

  componentDidMount() {

  }

  submitNewDeck = () => {
    fetch(`/words/decks/new`, {
      method: "POST",
      credentials: "same-origin",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({
        deckTitle: this.state.newDeckName,
        language: this.props.selectedLanguage,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status == "success") {
          this.setState({
            newDeckName: "",
            showNewDeckModal: false,
          });
          fetchDecks();
        }
      });
  };

  // blur and focus make it so the spacebar event on wordspage does not trigger card flip, and it will allow spaces in the textboxes
  handleInputFocus = () => {
    this.props.toggleSpacebarActive(false);
  };

  handleInputBlur = () => {
    this.props.toggleSpacebarActive(true);
  };

  onChangeNewDeckName = (e) => {
    if (e.target.value.length > 20) return;
    this.setState({ newDeckName: e.target.value });
  };

  onDeleteDeck = (e, deck) => {
    e.stopPropagation();
    removeDeck(deck);
  };

  onRenameDeck = (e, deck) => {
    if(deck.title==='General') return message.warning('You cannot rename this deck.')
    e.stopPropagation();
    this.setState({renameDeckModal: {
      visible: true,
      deck: deck,
      originalTitle: deck.title
    }})
  };

  submitDeckRename = () => {
    let newName = this.state.renameDeckModal.deck.title;
    let nameTaken = false;
    Object.values(this.props.deckInfo.decks).forEach(deck => {
      if(deck.title === newName) nameTaken = true
    })
    if(nameTaken) return message.warn("This deck name already exists")
    this.setState({renameDeckModal: {deck: {}}})
    modifyDeck(this.state.renameDeckModal.deck, this.state.renameDeckModal.originalTitle);
    message.success("Renamed!")
  }

  onChangeRenameDeckText = e => {
    this.setState({
      renameDeckModal: {
        ...this.state.renameDeckModal,
        deck: {
          ...this.state.renameDeckModal.deck,
          title: e.target.value
        }
      }
    })
  }

  getFilteredLessonDecks = () => {
    return Object.values(this.props.lessonDeckInfo.decks).filter(x => x.language === this.props.selectedLanguage)
  }

  render() {
    const { selectedTab } = this.state;
    let decks = this.props.deckInfo.decks;
    return (
      <div style={styles.wrapper}>
        <CustomModal
          visible={this.state.showNewDeckModal}
          onClose={() => this.setState({ showNewDeckModal: false })}
          submitButtonText={"Create"}
          title="Create New Deck"
          onSubmit={this.submitNewDeck}
          marginBottom={isMobile ? 0 : 300}
        >
          <div style={{ padding: 20 }}>
            <input
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur}
              class="form-control"
              style={{ width: "100%" }}
              placeholder="Enter Deck Name"
              style={{ minHeight: 50 }}
              onChange={this.onChangeNewDeckName}
              value={this.state.newDeckName}
            />
          </div>
        </CustomModal>
        <CustomModal
          visible={this.state.renameDeckModal.visible}
          onClose={() => this.setState({ renameDeckModal: {deck: {}} })}
          submitButtonText={"Submit"}
          title="Edit Deck Name"
          onSubmit={this.submitDeckRename}
          marginBottom={isMobile ? 0 : 300}
        >
          <div style={{ padding: 20 }}>
            <input
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur}
              class="form-control"
              style={{ width: "100%" }}
              placeholder="Enter Deck Name"
              style={{ minHeight: 50 }}
              onChange={this.onChangeRenameDeckText}
              value={this.state.renameDeckModal.deck.title}
            />
          </div>
        </CustomModal>
        <div style={styles.topRow}>
          <div style={styles.tabContainer}>
            <div
              style={selectedTab === "decks" ? { ...styles.tab, ...styles.selectedTab } : styles.tab}
              onClick={() => this.setState({ selectedTab: "decks" })}
            >
              My Decks
            </div>
            <div
              style={selectedTab === "lessons" ? { ...styles.tab, ...styles.selectedTab } : styles.tab}
              onClick={() => this.setState({ selectedTab: "lessons" })}
            >
              Lessons
            </div>
          </div>
          {isMobile || selectedTab==='lessons' ? null : <StorylingButton text="New Deck" width={150} onClick={() => this.setState({ showNewDeckModal: true })} />}
        </div>
        <div style={styles.decksRow}>
          {selectedTab === "decks" ? (
            <React.Fragment>
              {/* <DeckSquare title={'General'} list={this.props.wordList} color={defaultColor} onClick={() => this.props.onSelectDeck({title: 'General', color: defaultColor})}/> */}
              {Object.values(decks).map((d) => (
                <DeckSquare
                  onDelete={(e) => this.onDeleteDeck(e, d)}
                  onRenameDeck={(e) => this.onRenameDeck(e, d)}
                  title={d.title}
                  list={d.list}
                  color={d.color}
                  onClick={() => this.props.onSelectDeck(d)}
                  isLessonDeck={d.isLessonDeck}
                />
              ))}
            </React.Fragment>
          ) : (
            this.getFilteredLessonDecks().map((d) => (
              <DeckSquare
                onDelete={(e) => this.onDeleteDeck(e, d)}
                onRenameDeck={(e) => this.onRenameDeck(e, d)}
                title={d.title}
                list={d.list}
                color={d.color}
                onClick={() => this.props.onSelectLessonDeck(d)}
                isLessonDeck={d.isLessonDeck}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    padding: isMobile ? 10 : 20,
  },
  decksRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 30,
    position: "relative",
    justifyContent: isMobile ? "space-between" : undefined,
  },
  topRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    width: isMobile ? "100%" : undefined,
    justifyContent: isMobile ? "center" : undefined,
  },

  tab: {
    width: 170,
    height: 60,
    fontSize: 16,
    borderBottomWidth: 5,
    borderBottomStyle: "solid",
    borderBottomColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    color: "#A7AEBC",
    cursor: "pointer",
    backgroundColor: "#F8F9FB",
    paddingTop: 5,
    // height: 47
  },
  selectedTab: {
    borderBottomColor: "#0D55CF",
    color: "#0D55CF",
  },
};

const mapStateToProps = (state) => ({
  selectedLanguage: state.selectedLanguage,
  wordList: state.wordList,
  deckInfo: state.deckInfo,
  lessonDeckInfo: state.lessonDeckInfo
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(DecksSection);
