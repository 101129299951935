import store from "../redux/store";
import * as types from "../redux/types";

export function getGeneralDeckId(decksToUse) {
  let decks = decksToUse || store.getState().deckInfo.decks;
  let generalId = "";
  Object.values(decks).forEach((d) => {
    if (d.title === "General") generalId = d.id;
  });
  return generalId;
}

export function fetchDecks() {
  return new Promise(resolve => {
    let selectedLanguage = store.getState().selectedLanguage;
    // alert('asd')
  
    fetch("/words/decks/getall", {
      method: "get",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((result) => {
        console.log("ressss", result);
        if (result.decks) {
          console.log("selectedlang", selectedLanguage);
  
          let currentDecks = result.decks.filter((x) => (x.language === selectedLanguage ? true : false));
          store.dispatch({
            type: types.HYDRATE_DECKS,
            payload: currentDecks,
          });
          resolve()
        }
      });
  })
}

export function selectDeckId(deckId) {
  store.dispatch({
    type: types.SELECT_DECK_ID,
    payload: deckId,
  });
}

// export function modifyDeck(deck){
//   store.dispatch({
//     type: types.MODIFY_DECK,
//     payload: deck
//   })
// }

export function modifyDeck(deck, originalTitle) {
  let selectedLanguage = store.getState().selectedLanguage;
  store.dispatch({
    type: types.MODIFY_DECK,
    payload: {deck: deck},
  });

  return fetch("/words/decks/modify", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deck: deck,
      originalTitle: originalTitle || deck.title,
    }),
  })
  .then((result) => result.json())
  .then((result) => {});
}

export function removeDeck(deck){
  if(deck.title==='General') return alert('You cannot delete the general deck')
  let state = store.getState();
  let newDecks = {...state.deckInfo.decks};
  delete newDecks[deck.id];
  store.dispatch({
    type: types.HYDRATE_DECKS,
    payload: Object.values(newDecks)
  })
  fetch(`/words/decks/delete`, {
    method: "POST",
    credentials: "same-origin",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify({
      language: deck.language,
      deckTitle: deck.title
    })
  })
  .then(result => result.json())
  .then(result => {
    
  })
}
